<template>
  <div class="row">
    <div class="col-6 mt-3">
      <div class="white-box h-100">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 pb-0 row justify-content-center">
          <div class="col-12">
            <h2 class="bold-19">
              {{ $t("inventoryAnalytics.salesForecast") }}
            </h2>
            <LineChart :chartData="salesByMonths" :options="options" />
          </div>
          <div class="col-12">
            <router-link to="/analytics/forecast" class="btn btn-line white">
              <span> {{ $t("inventoryAnalytics.viewDetails") }} </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 mt-3">
      <div class="white-box h-100">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 row justify-content-center">
          <h2 class="bold-19">
            {{ $t("inventoryAnalytics.warehouseStocks") }}
          </h2>
          <div class="col-10 mt-2 mb-4">
            <PieChart
              :chartData="byWarehouse"
              :options="{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return (
                          context.dataset.label +
                          ' ' +
                          context.label +
                          ': ' +
                          context.formattedValue
                        );
                      },
                    },
                  },
                },
              }"
            />
          </div>
          <div>
            <div class="row">
              <div class="col-6"></div>
              <div class="col-3">{{ $t("inventoryAnalytics.stock") }}</div>
              <div class="col-3">{{ $t("inventoryAnalytics.reserved") }}</div>
            </div>
            <div class="row" v-for="(row, i) in data.warehouses" :key="row.id">
              <div class="col-1">
                <span
                  class="color-circle"
                  :style="{ background: getColor(i) }"
                ></span>
              </div>
              <div class="col-5">
                {{ row.name }}
              </div>
              <div class="col-3">
                {{ row.product_quantity }}
              </div>
              <div class="col-3">
                {{ row.product_quantity - row.available_quantity }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 mt-3 row">
      <div class="col-12">
        <div class="white-box text-center center-box px-2 pt-3 pb-0">
          <div class="row">
            <div class="col-4">
              <h2 class="bold-19 text-default mb-2">
                {{ $t("menu.active") }}
              </h2>
              <h2 class="bold-24 text-default">
                {{ data?.statuses?.activeItems }}
              </h2>
            </div>
            <div class="col-4">
              <h2 class="bold-19 text-black mb-2">
                {{ $t("menu.draft") }}
              </h2>
              <h2 class="bold-24 text-black">
                {{ data?.statuses?.draftItems }}
              </h2>
            </div>
            <div class="col-4">
              <h2 class="bold-19 text-gray mb-2">
                {{ $t("menu.archive") }}
              </h2>
              <h2 class="bold-24 text-gray">
                {{ data?.statuses?.archiveItems }}
              </h2>
            </div>
            <div class="col-12">
              <router-link
                to="/analytics/stock/value"
                class="btn btn-line white"
              >
                <span> {{ $t("inventoryAnalytics.viewInventoryValue") }} </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="white-box text-center center-box px-2 pt-3 pb-0">
          <div class="row">
            <div class="col-6">
              <h2 class="bold-19 text-default mb-2">
                {{ $t("inventoryAnalytics.lowStock") }}
              </h2>
              <h2 class="bold-24 text-default">
                {{ data?.statuses?.lowStock }}
              </h2>
            </div>
            <div class="col-6">
              <h2 class="bold-19 text-danger mb-2">
                {{ $t("inventoryAnalytics.outOfStock") }}
              </h2>
              <h2 class="bold-24 text-danger">
                {{ data?.statuses?.emptyStock }}
              </h2>
            </div>
            <div class="col-12">
              <router-link to="/analytics/stock/low" class="btn btn-line white">
                <span> {{ $t("inventoryAnalytics.viewDetails") }} </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="white-box text-center center-box px-2 pt-3 pb-0">
          <div class="row">
            <div class="col-12">
              <h2 class="bold-19 text-gradient mb-2">
                {{ $t("inventoryAnalytics.deadStock") }}
              </h2>
              <h2 class="bold-24 text-gradient">
                {{ data?.statuses?.deadStock }}
              </h2>
            </div>
            <div class="col-12">
              <router-link
                to="/analytics/stock/dead"
                class="btn btn-line white"
              >
                <span> {{ $t("inventoryAnalytics.viewDetails") }} </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 mt-3 mb-3">
      <div class="white-box h-100">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 row justify-content-center">
          <h2 class="bold-19">{{ $t("inventoryAnalytics.ABCAnalytics") }}</h2>
          <div class="col-10 mt-2 mb-4">
            <PieChart
              :chartData="byAbc"
              :options="{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }"
            />
          </div>
          <div>
            <div class="row">
              <div class="col-4"></div>
              <div class="col-4">
                {{ $t("inventoryAnalytics.productVariants") }}
              </div>
              <div class="col-4">
                {{ $t("inventoryAnalytics.incomePercent") }}
              </div>
            </div>
            <div class="row" v-for="(row, i) in data.abc_analytics" :key="row">
              <div class="col-1">
                <span
                  class="color-circle"
                  :style="{ background: getColor(i) }"
                ></span>
              </div>
              <div class="col-3">
                {{ getGradeValue(row.abc_category) }}-grade
              </div>
              <div class="col-4">{{ row.quantity }}</div>
              <div class="col-4" v-if="row.abc_category == 1">80%</div>
              <div class="col-4" v-else-if="row.abc_category == 2">15%</div>
              <div class="col-4" v-else-if="row.abc_category == 3">5%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-9 mt-3 mb-3">
      <div class="white-box">
        <div class="white-box-top"></div>
        <div>
          <OneByOneTable
            v-model:rows="warehouseRows"
            v-model:items="data.warehouses"
            :show-add="false"
            :show-edit="false"
            :show-del="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/modules/http";
import { useStore } from "vuex";
import date from "../../modules/date";
import { LineChart, PieChart } from "vue-chart-3";
import OneByOneTable from "../../components/lists/OneByOneTable.vue";

export default {
  name: "InventoryAnalytics",
  components: { OneByOneTable, PieChart, LineChart },
  data() {
    return {
      store: useStore(),
      data: {},
      total: {},
      colors: [
        "#4CC9F0",
        "#F9DB6D",
        "#464D77",
        "#F71735",
        "#04A777",
        "#b585dc",
        "#3A0CA3",
        "#F79824",
        "#4F5165",
        "#6FAE90",
        "#7D2E68",
        "#502419",
        "#645DD7",
        "#C7CB85",
        "#C7FFED",
        "#9DB5B2",
        "#55505C",
      ],
      warehouseRows: [
        {
          name: this.$t("inventoryAnalytics.name"),
          key: "name",
          type: "text",
          show: true,
          editable: false,
        },
        {
          name: this.$t("inventoryAnalytics.stock"),
          key: "product_quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: this.$t("inventoryAnalytics.inventoryValue"),
          key: "inventory_value",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: this.$t("inventoryAnalytics.AvgInventoryValue"),
          key: "product_value",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: this.$t("inventoryAnalytics.turnoverRatio"),
          key: "turnover_ratio",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: this.$t("inventoryAnalytics.warehouseSize"),
          key: "warehouse_size_in_units",
          type: "number",
          show: true,
          editable: false,
        },
      ],
      optionsNoText: {
        padding: 0,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
      },
      options: {
        padding: 0,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
      },
      byAbc: {
        labels: [],
        datasets: [
          {
            label: "Product number",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      byWarehouse: {
        labels: [],
        datasets: [
          {
            label: "Current stock",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
          {
            label: "Available stock",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      salesByMonths: {
        labels: [],
        datasets: [
          {
            label: "Sales",
            data: [],
            lineTension: 0.45,
            borderColor: "rgba(131,201,230,1)",
            backgroundColor: "rgba(131,201,230,0.3)",
            fill: true,
          },
          {
            label: "Forecast",
            data: [],
            lineTension: 0.45,
            borderColor: "rgba(160,85,224,1)",
            backgroundColor: "rgba(160,85,224,0.3)",
            fill: true,
          },
        ],
      },
    };
  },
  computed: {
    date() {
      return this.store.state.topBar.date;
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
  },
  watch: {
    date: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatDate(val) {
      return date.format(val, false, false);
    },
    percent(val) {
      return Math.round(val * 10000) / 100;
    },
    getGradeValue(a) {
      if (a === 1) {
        return "A";
      }
      if (a === 2) {
        return "B";
      }
      if (a === 3) {
        return "C";
      }
      return "-";
    },
    loadData() {
      http
        .fetch(
          `/analytics/inventory?1${this.q ? "&q=" + this.q : ""}${
            (this.date[0]
              ? "&from=" + this.date[0].toISOString().split("T")[0]
              : "") +
            (this.date[1]
              ? "&to=" + this.date[1].toISOString().split("T")[0]
              : "")
          }`
        )
        .then((data) => {
          this.data = data;

          let labels = [];
          let forecasts = [];
          let sales = [];
          for (const sale of data.sales) {
            sales.push(Number.parseFloat(sale.quantity));
          }
          for (const forecast of data.forecast) {
            labels.push(this.formatDate(forecast.month));
            forecasts.push(Number.parseFloat(forecast.quantity));
          }
          this.salesByMonths.labels = labels;
          this.salesByMonths.datasets[0].data = sales;
          this.salesByMonths.datasets[1].data = forecasts;

          this.setChart(data.abc_analytics, this.byAbc, "quantity", 0);

          this.setChart(
            data.warehouses,
            this.byWarehouse,
            "product_quantity",
            0
          );
          this.setChart(
            data.warehouses,
            this.byWarehouse,
            "available_quantity",
            1
          );
        });
    },
    getColor(i) {
      let e = i - this.colors.length * Math.floor(i / this.colors.length);
      return this.colors[e];
    },
    setChart(data, chartset, row = "count", i = 0) {
      let chart = [];
      let colors = [];
      let labels = [];
      for (const i in data) {
        labels.push(data[i].name);
        chart.push(data[i][row]);
        colors.push(this.getColor(i));
      }

      chartset.labels = labels;
      chartset.datasets[i].data = chart;
      chartset.datasets[i].backgroundColor = colors;
    },
  },
};
</script>
